import React, { useState } from 'react'
import { Upload, Button, Typography, Row, Col, Input, Form, message, Alert  } from 'antd';
import 'antd/dist/antd.css';
import '../App.css';
import { baseurl } from '../lib/settings';
const key = 'updatable';

const { TextArea } = Input;
const { Title } = Typography;

export default function Manifesto() {
    const[carImage, setCarImage] = useState([]);
  const[carDetails, setCarDetails] = useState([]);
  const[loading, setLoading] = useState(false);
  const[messages, setMessages] = useState('');
  const[control, setControl] = useState('');
  const [form] = Form.useForm();

  const onChange = (newFileList) => {
    const array = []
    newFileList.fileList.map((img)=>{
      array.push(img['originFileObj'])
    })

    const imgs ={
      img:array
    }
    setCarImage(array);
  }
  const loadingMessage = () => {
    message.loading({
      content: 'Loading...',
      key,
      duration:4

    });
    
  };

  const sucessMessage =()=>{
    setTimeout(() => {
      message.success({
        content: 'Submetido com sucesso',
        key,
        duration: 3,
      });
    }, 1000);
  }

  const errorMessage =()=>{
    setTimeout(() => {
      message.error({
        content: 'Falha na submicao',
        key,
        duration: 2,
      });
    }, 1000);
  }

  function sendData(value){
    setLoading(true)
    const config = {
      headers: { 'content-type': 'multipart/form-data' }
     }
    const data ={
      car_code: value.apolice,
      description: "Confirmacao do manifesto",
      img_car: carImage
    }
    console.log("Tamanho das imagens:", carImage.length)
    console.log("Dados antes de enviar:", data,config)
    baseurl.post('v1/manifesto/', data,config).then((resp)=>{
      setCarDetails(resp.data)
      setControl('success')
      setLoading(false)
    }).catch((error)=>{
      console.log("Error server", error)
      setControl('error')
      setMessages(`${error.response.data}`)
      setLoading(false)

    })
  }
    return(
        <Row
        type="flex"
        style={{ alignItems: 'center' }}
        justify="center"
      >
         <Col xs={21} xl={18} >

         {control === 'success' && <><Alert message="Submetido com sucesso" type="success" showIcon /><br /></>}
        {control === 'error' && <><Alert message={`${messages}`} type="error" showIcon /><br /></>}
    
        </Col>
      <Col xs={21} xl={18} >
      <Typography>
        <Title align="center" level={5}>Livrete</Title>
      </Typography>
      </Col>
      <br />
        <Col xs={21} xl={18} >

            <Upload.Dragger 
              multiple 
              listType='picture'
              onChange={onChange}
              disabled={loading}
              >
                Tirar ou carregar foto do Livrete
              <br />
                
            </Upload.Dragger>
                
        </Col>
        <Col xs={21} xl={18} >
          <div><br />
          <Form
            form={form}
            id={"roomID"}
            name="basic"
            layout='vertical'
            onFinish={sendData} >

            <Form.Item
                name='apolice'
                // label="Matricula"
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
              <Input disabled={loading} placeholder='Código do manifesto' />
            </Form.Item>
            <Form.Item>
              <Button 
                loading={loading}
                type="primary" 
                htmlType="submit" 
                block>
                Submeter
                </Button>
              </Form.Item>
          </Form>
          </div>
        </Col>
        
      </Row>
    )
}