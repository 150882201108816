import React, { useState } from 'react'
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import './App.css';
import CarConfirmations from './pages/carConfirmation';
import Dashboard from './pages/dashboard';
import Manifesto from './pages/manifesto';




function App() {
 

  return(
    <>
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route exact path="/dashboard" element={<Dashboard />} />
        <Route exact path="/car_confirm" element={<CarConfirmations />} />
        <Route exact path="/manifesto_confirm" element={<Manifesto />} />
        

      </Routes>
    </>
  )
  
}

export default App;
