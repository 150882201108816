import React, { useState } from 'react'
import { Upload, Button, Typography, Row, Col, Input, Form, message, Alert  } from 'antd';
import 'antd/dist/antd.css';
import { useNavigate } from "react-router-dom";

export default function Dashboard(){
    const navigate = useNavigate ()

    function changPage(url){
        navigate(url)
    }
    return(
           <Row
        type="flex"
        style={{ alignItems: 'center' }}
        justify="center"
      >
         <Col xs={21} xl={18} >

        <Button onClick={() =>changPage('manifesto_confirm/')}>Manifesto</Button>
    
        </Col>
        <Col xs={21} xl={18} >
        <Button onClick={() =>changPage('car_confirm/')}>Confirmacao Automovel</Button>
    
        </Col>
        </Row>
        
    )
}